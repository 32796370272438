import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";

import router from "./router";
import vuetify from "@/plugins/vuetify";

import config from "./config.js";
const apiUrl = config.apiUrl;
const chorUrl = config.chorUrl;
import ApiService from "@/plugins/api-service";
import moment from "moment";
//import { VuePlausible } from "vue-plausible";

Vue.use(ApiService, { url: apiUrl });

Vue.use(VueRouter);

Vue.config.productionTip = false;

Vue.use({
  install(Vue_) {
    Vue_.prototype.$chorUrl = chorUrl;
  },
});

Vue.use({
  install(Vue_) {
    Vue_.prototype.$moment = moment;
  },
});

//const domain = process.env.PLAUSIBLE_DOMAIN || "https://ecbg-rehe.de";
// const apiHost = process.env.PLAUSIBLE_API_HOST || "https://plausible.dlion.de";

// Vue.use(VuePlausible, {
//   plausible: {
//     // Use as fallback if no runtime config is available at runtime
//     domain: domain,
//     apiHost: apiHost,
//     trackLocalhost: true,
//   },
//   publicRuntimeConfig: {
//     plausible: {
//       domain: domain,
//       apiHost: apiHost,
//       trackLocalhost: true,
//     },
//   },
// });

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
